<template>
  <div class="dropdown">
    <span
      id="dropdownMenuButton1"
      class="position-relative notification-icon"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="getAllNotification"
    >
      <span v-if="notifications.countOfNotifications" class="notification on"></span>
      <img src="/images/bxs-bell.svg" alt="" />
    </span>

    <ul class="snapr-notification dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <n-spin v-if="isLoading" size="medium" :show="isLoading" class="spinner" />
      <template v-else>
        <template v-if="notifications.notificationsList.length">
          <template v-for="nlist in notifications.notificationsList" :key="nlist.id.toString()">
            <li
              class="d-flex align-items-center justify-content-between goto"
              role="button"
              @click="goto(nlist.type, nlist.transaction_id, nlist.id)"
            >
              <div class="who-sent">
                <img
                  :src="getSenderImage(nlist.image)"
                  :alt="nlist.sender_id.toString()"
                  class="d-inline-block align-middle me-2 rounded"
                />
                <span class="d-inline-block align-middle">{{ nlist.name }}</span>
                <p class="mb-0 mt-2">at {{ formatDate(nlist.created_at) }}</p>
              </div>
              <span class="amount">{{ formatNumber(nlist.message) }}</span>
              <span v-if="nlist.status === 'unseen'" class="dot"></span>
            </li>
          </template>
        </template>
        <li v-else class="d-flex align-items-center justify-content-between">
          <p>You have no unseen notifications</p>
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const { $snapApi, $isVerifiedByDwolla, $toastError } = useNuxtApp();
const notifications = useNotifications();
const router = useRouter();
const { data } = useAuth();

const $auth = data.value;
const isLoading = ref<boolean>(false);
const timer = ref<NodeJS.Timeout>();

const timeout = 1000000;

onMounted(async () => {
  timer.value = setInterval(async () => {
    await getTotalNotification();
  }, timeout);

  await getTotalNotification();
});

onUnmounted(() => {
  clearInterval(timer.value);
});

const getAllNotification = async () => {
  try {
    isLoading.value = true;
    await notifications.fetchList();
  } catch {
  } finally {
    isLoading.value = false;
  }
};

const getTotalNotification = async () => {
  try {
    await notifications.fetchCount();
    const notificationCount = notifications.countOfNotifications;

    if (!notificationCount) {
      clearInterval(timer.value);
    }
  } catch {}
};

const getSenderImage = (path: string | null) => {
  const config = useRuntimeConfig();
  if (path === '0') {
    return '/images/ionic-ios-wallet.svg';
  }

  return path ? `${config.public.BASE_URL}/image/${path}` : '/images/avatar.png';
};

const goto = (type: number | null, id: number | null, notyID: number) => {
  if (!$isVerifiedByDwolla($auth?.verifiedWithDwolla) && $auth?.type !== 'receive-only') {
    $toastError('Please finish the verification process first.');
    router.push({ path: '/send-payment/' });
    return;
  }
  updateUnseenNotification(notyID);
  notifications.countOfNotifications = 0;

  router.push({ path: type && Number(type) === 1 ? `/pending-payment/${id}` : '/transactions' });
};

const updateUnseenNotification = async (id: number) => {
  try {
    await $snapApi({
      method: 'POST',
      url: 'update-unseen',
      data: {
        notyID: id,
      },
    });
  } catch {}
};
</script>

<style scoped lang="scss">
.spinner {
  display: flex;
  align-items: center;
}

.snapr-notification {
  position: absolute;
  z-index: 2;
  opacity: 1;
  top: 24px !important;
  width: 290px;
  padding: 10px;
  background: $grayish-blue;
  box-shadow: 7px 6px 9px -2px #0000008c;
  border-radius: 10px;
  right: 150px;
  transform: translateY(0px);
  max-height: 430px;
  overflow: auto;
  transition: all ease 0.3s;
  color: white;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(158, 158, 158);
    outline: 1px solid slategrey;
  }
  li {
    padding: 10px 0;
    border-bottom: 1px solid #dbdbdb26;
  }
  .who-sent {
    img {
      width: 25px;
      height: 25px;
    }
  }
  .amount {
    font-size: 15px;
    font-weight: 600;
    color: white;
  }
  &.open {
    opacity: 1;
    z-index: 2;
    transform: translateY(0px);
  }
}

@media (max-width: 575px) {
  .snapr-notification {
    top: 62px;
    right: 0px;
  }
  .goto {
    cursor: pointer;
  }
}

.dot::after {
  content: '';
  background: $medium-green;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  left: 10px;
  top: 10px;
  display: inline-block;
}

.notification-icon {
  cursor: pointer;

  .notification {
    position: absolute;
    top: 0px;
    right: -7px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $dark-blue;
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 3px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $dark-gray;
    }
    &::after {
      background-color: $medium-green;
    }
  }
}
</style>
