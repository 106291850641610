<template>
  <n-space vertical size="large">
    <n-layout position="absolute" has-sider :style="{ minHeight: '100vh' }">
      <n-layout-sider
        bordered
        :position="sidebarPosition"
        show-trigger
        collapse-mode="width"
        :collapsed="collapsed"
        :collapsed-width="collapsedWidth"
        :width="300"
        :native-scrollbar="false"
        :style="{ zIndex: 999 }"
        @collapse="collapsed = true"
        @expand="collapsed = false"
      >
        <n-layout-header style="padding: 24px 0">
          <n-image class="logo" height="40" :src="logoSrc" object-fit="cover" alt="logo" preview-disabled />
        </n-layout-header>
        <n-layout-content
          position="absolute"
          :native-scrollbar="false"
          class="sidebar-menu-wrapper"
          :theme-overrides="{ color: 'transparent' }"
        >
          <n-menu :collapsed-width="104" :collapsed-icon-size="24" style="margin: 0 16px" :options="menuOptions" />
        </n-layout-content>
        <n-layout-footer position="absolute" bordered>
          <n-menu
            :collapsed-width="104"
            :collapsed-icon-size="24"
            :options="footerMenuOptions"
            style="margin: 0 16px"
            @update:value="footerMenuClickHandler"
          />
        </n-layout-footer>
      </n-layout-sider>
      <slot />
    </n-layout>
  </n-space>
</template>

<script setup lang="ts">
import { Logout } from '@vicons/tabler';
import debounce from 'debounce';
import type { MenuOption } from 'naive-ui';

defineProps<{
  menuOptions: MenuOption[];
  logoSrc: string;
  title: string;
}>();

const profileStore = useProfileStore();

const { sidebarCollapsedData: collapsed, setSidebarCollapsed } = useSidebarCollapse();
const collapsedWidth = ref<number>(104);
const sidebarPosition = ref<'static' | 'absolute'>('static');

const footerMenuOptions: MenuOption[] = [
  {
    label: 'Logout',
    key: 'logout',
    icon: renderIcon(Logout),
  },
];

const footerMenuClickHandler = async (key: string) => {
  if (key === 'logout') {
    await profileStore.signOut();
  }
};

const handleResize = (): void => {
  const isDesktop = window.innerWidth >= 768;
  collapsedWidth.value = isDesktop ? 104 : 0;
  sidebarPosition.value = isDesktop ? 'static' : 'absolute';
  setSidebarCollapsed(!(window.innerWidth >= 1200));
};

const resizeEventHandler = debounce(handleResize, 100);

onMounted(() => {
  handleResize();
  window.addEventListener('resize', resizeEventHandler);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeEventHandler);
});
</script>

<style lang="scss">
.sidebar-menu-wrapper .n-menu .n-menu-item-content-header {
  height: 24px;
}

.sidebar-menu-wrapper .n-menu:has(.router-link-active) {
  .n-menu-item-content--selected:not(:has(.router-link-active)) {
    &:hover::before {
      background-color: $medium-green !important;
    }

    &::before {
      background-color: unset !important;
    }

    & .n-menu-item-content-header a {
      color: $menu-inactive;
    }
  }

  :hover .n-menu-item-content--selected:not(:has(.router-link-active)) {
    & .n-menu-item-content-header a {
      color: $white;
    }
  }
}

.sidebar-menu-wrapper .n-menu .n-menu-item-content .n-menu-item-content-header:has(.router-link-active) a,
.sidebar-menu-wrapper .n-menu .n-menu-item-content:hover a {
  color: $white;
  font-weight: 700;
}

.sidebar-menu-wrapper .n-menu .n-menu-item-content:has(.router-link-active) {
  &::before {
    background-color: $medium-green;
    border-color: $light-green;
  }

  .n-menu-item-content__icon {
    color: $white;
  }
}

.sidebar-menu-wrapper .n-menu .n-menu-item-content:not(:has(.router-link-active)) {
  .n-menu-item-content__icon {
    color: $menu-inactive;
  }

  &:hover {
    .n-menu-item-content__icon {
      color: $white;
    }
  }
}

.n-scrollbar-content .n-menu {
  padding-bottom: 20px;
}

.n-scrollbar-content > .n-menu .n-menu-item {
  margin-top: 20px;
  height: 56px;
}
</style>

<style scoped lang="scss">
:deep(.n-layout-sider .n-layout-toggle-button) {
  width: 48px;
  height: 48px;

  .n-base-icon,
  svg {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.logo {
  padding-left: 35px;
}

.n-layout-sider--collapsed .logo :deep(img) {
  width: 34px;
  object-position: left;
}

.n-layout-header :deep(.n-space div:has(.logo)) {
  height: 40px;
}

.n-layout-sider :deep(.n-menu) {
  font-size: 16px;
}

.title {
  font-size: 24px;
  font-weight: 700;
}

.n-menu {
  :deep(.n-menu-item-content) {
    height: 56px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px !important;
  }

  :deep(.n-menu-item-content__icon) {
    margin-right: 16px !important;
    font-size: 24px !important;
  }
}

.sidebar-menu-wrapper {
  top: 88px;
  max-height: calc(100% - 88px - 69px);
}

.sidebar-menu-wrapper {
  :deep(.n-menu .n-menu-item-content),
  :deep(.n-menu .n-menu-item-content::before) {
    border-radius: 16px;
  }
}

.n-layout-sider--collapsed .sidebar-menu-wrapper {
  :deep(.n-menu .n-menu-item-content),
  :deep(.n-menu .n-menu-item-content::before) {
    border-radius: 8px;
  }
}
</style>
