<template>
  <header class="header">
    <span class="d-block d-md-none mobile-menu-trigger" @click="toggleSidebar">
      <i class="fa-solid fa-bars" />
    </span>
    <NuxtLink to="/" class="link">Dashboard</NuxtLink>
    <div class="right-header-block">
      <Notifications />
      <n-divider vertical :theme-overrides="{ color: 'white' }" />
      <ProfileDropdown :image-src="imageSrc" :user-name="$auth?.firstName" />
    </div>
  </header>
</template>

<script setup lang="ts">
const { public: publicEnv } = useRuntimeConfig();
const { data: $auth } = useAuth();

const { sidebarCollapsedData, setSidebarCollapsed } = useSidebarCollapse();

const imageSrc = computed(() => {
  return $auth.value?.imageFileName ? `${publicEnv.BASE_URL}/image/${$auth.value.imageFileName}` : '/images/avatar.png';
});

const toggleSidebar = () => {
  setSidebarCollapsed(!sidebarCollapsedData);
};
</script>

<style scoped lang="scss">
.header {
  height: 68px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  font-size: 20px;
  font-weight: 700;
  color: $white;
  text-decoration: none;
  transition: color 0.3s ease;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.right-header-block {
  max-width: 201px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.n-divider.n-divider--vertical {
  background-color: $divider-color;
}

@media (max-width: 767px) {
  .mobile-menu-trigger {
    font-size: 25px;
    padding: 8px;
    margin-right: 15px;
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover {
      color: white;
    }
  }
}
</style>
