<template>
  <div
    v-if="isMessageVisible && reasonText"
    class="p-3 d-flex flex-row round-5 mb-4"
    :class="{ 'user-status': isMessageVisible }"
  >
    <i class="fa-solid fa-lock"></i>
    <div v-show="isMessageVisible" class="notification-text">
      <h4 class="mb-0">Your account is in {{ checkStatus }} status.</h4>
      <p class="mb-0 text-white">
        Please contact our support team
        <a :href="`mailto:support@snaprefund.io?subject=${$auth?.profileId}`"> support@snaprefund.io </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const isMessageVisible = ref<boolean>(false);
const reasonText = ref<string>('');

const { $isVerifiedByDwolla, $isPlaidButtonVisible } = useNuxtApp();
const { data } = useAuth();
const $auth = data.value;

const calculateStatus = () => {
  if ($auth?.verifiedWithDwolla) {
    reasonText.value = $auth?.verifiedWithDwolla ?? '';
    isMessageVisible.value = !$isVerifiedByDwolla($auth?.verifiedWithDwolla);
  } else {
    reasonText.value = $auth?.verifiedWithPlaid ?? '';
    isMessageVisible.value = !$isPlaidButtonVisible($auth?.verifiedWithPlaid);
  }

  if (reasonText.value === 'document') {
    reasonText.value += ' verification';
  }
};

onMounted(() => {
  calculateStatus();
});

const checkStatus = computed(() => (reasonText.value === 'false' ? 'unverified' : reasonText.value));
</script>

<style scoped>
.user-status {
  background: #dc3545;
  border-radius: 5px;
  display: block;
  i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #bf1d1d;
    font-size: 24px;
    border-radius: 5px;
    color: white;
    margin-right: 20px;
  }
  h4 {
    font-weight: 600;
    color: white;
  }
  .notification-text {
    p {
      a {
        color: #44ed26;
      }
    }
  }
}
</style>
