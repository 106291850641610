<template>
  <n-dropdown
    trigger="hover"
    size="small"
    :options="options"
    class="profile-dropdown"
    style="width: 160px; margin-top: 18px"
    :theme-overrides="ProfileDropdownThemeOverrides"
    @select="handleSelect"
  >
    <n-space align="center" :size="8" :wrap="false">
      <div class="avatar">
        <n-avatar
          lazy
          round
          object-fit="contain"
          :size="32"
          :src="imageSrc"
          fallback-src="/images/avatar.png"
          :intersection-observer-options="{
            root: '.avatar',
          }"
        />
      </div>
      <n-ellipsis style="max-width: 73px" class="name-wrapper text-white">{{ userName }}</n-ellipsis>
      <n-icon>
        <i class="fa-light fa-angle-down" />
      </n-icon>
    </n-space>
  </n-dropdown>
</template>

<script lang="ts" setup>
import variables from '../assets/scss/config/variables.module.scss';

enum ProfileOptionType {
  Settings = 'Settings',
  Logout = 'Logout',
}

const options = [
  {
    label: 'Settings',
    key: ProfileOptionType.Settings,
  },
  {
    label: 'Logout',
    key: ProfileOptionType.Logout,
  },
];

defineProps({
  imageSrc: {
    type: String,
    required: false,
    default: '/images/avatar.png',
  },
  userName: {
    type: String,
    required: false,
    default: '',
  },
});

const profileStore = useProfileStore();

const ProfileDropdownThemeOverrides = {
  color: variables.grayishBlue,
  optionColorHover: variables.grayishBlue,
  optionTextColor: variables.lightGray,
  optionTextColorHover: variables.white,
  padding: '20px 16px',
  fontSizeHuge: '14px',
  optionPrefixWidthSmall: '0',
  optionHeightSmall: '24px',
};

const handleSelect = async (key: string | number) => {
  switch (String(key)) {
    case ProfileOptionType.Settings: {
      navigateTo('/profile');

      break;
    }
    case ProfileOptionType.Logout: {
      await profileStore.signOut();

      break;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar {
  width: 32px;
  height: 32px;

  .n-avatar {
    --n-border: 2px solid #69687f9f !important;
    --n-merged-color: $dark-blue;
  }
}

:global(.profile-dropdown .n-dropdown-option:not(:last-child)) {
  margin-bottom: 10px;
}

.name-wrapper {
  vertical-align: middle;
  color: $light-gray;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
}
</style>
