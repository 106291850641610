import type { NotificationModel } from '../common/dtos/notification.dto';

interface NotificationsState {
  notificationsList: NotificationModel[];
  countOfNotifications: number;
}

export const useNotifications = defineStore('notifications', {
  state: (): NotificationsState => ({
    notificationsList: [],
    countOfNotifications: 0,
  }),
  actions: {
    async fetchList() {
      const { $snapApi } = useNuxtApp();
      const res = await $snapApi({ method: 'get', url: 'notifications' });
      if (res && res.status === 200) {
        this.notificationsList = res.data.data;
      }
    },
    async fetchCount() {
      const { $snapApi } = useNuxtApp();
      const res = await $snapApi({ method: 'get', url: 'unseen' });
      if (res && res.status === 200) {
        this.countOfNotifications = res.data.snapSize;
      }
    },
  },
});
